const shippingTypeCorrespondance = {
    'Mondial Relay': 'mondial-relay',
    'Domicile': 'standard',
    'Express': 'express',
    'Tous': 'tous'
};

const getDetails = (promotion, customer) => {
    try {
        let shippingType = null;
        let percentageDiscount = null;
        let keepApplicationFee = null;
        let applyOnHeavy = null;
        let hisLoyaltyStatus = null;
        let hisLoyaltyRenew = null;
        const conditions = JSON.parse(promotion.conditions);
        const actions = JSON.parse(promotion.actions);
        if (!!conditions) {
            const shipping_promotion = conditions.find(c => c.code === "shipping_promotion");
            if (!!shipping_promotion) {
                shippingType = shipping_promotion.args.find(sa => sa.name === "shippingType")?.value;
            }
            const loyalty_condition = conditions.find(c => c.code === 'loyalty');
            if (!!loyalty_condition) {
                const loyaltyStatus = loyalty_condition.args.find(lc => lc.name === "loyaltyStatus")?.value;
                const onLoyaltyRenew = loyalty_condition.args.find(lc => lc.name === "onLoyaltyRenew")?.value;
                hisLoyaltyStatus = loyaltyStatus === "true"
                hisLoyaltyRenew = onLoyaltyRenew === "true"
            }
        }
        if (!!actions) {
            const shipping_discount = actions.find(c => c.code === "shipping_discount");
            if (!!shipping_discount) {
                percentageDiscount = shipping_discount.args.find(sa => sa.name === "percentageDiscount")?.value;
                keepApplicationFee = shipping_discount.args.find(sa => sa.name === "keepApplicationFee")?.value;
                applyOnHeavy = shipping_discount.args.find(sa => sa.name === "applyOnHeavy")?.value;
            }
        }
        if (!!hisLoyaltyStatus && !customer?.customFields?.loyaltyStatus) {
            return null;
        }
        if (!!hisLoyaltyRenew && !customer?.customFields?.loyaltyIsRenew) {
            return null;
        }
        return {
            shippingType: shippingTypeCorrespondance[shippingType],
            percentageDiscount: parseInt(percentageDiscount, 10),
            keepApplicationFee: keepApplicationFee === "true",
            applyOnHeavy: applyOnHeavy === "true",
        }
    } catch (e) {
        console.error(e);
        return null;
    }
}

const substractPercentage = (value, percentage) => {
    return value - ( value * ( percentage / 100) )
}

const calculateShippingPromotion = (shippingMethod, promotion, price, totalShippingPrice=false, isApplicationFee=false, isHeavy=false) => {
    if (!shippingMethod || !promotion) return price;
    const applicationFee = shippingMethod?.customFields?.applicationFee || 0
    const hasPromotion = promotion.find(p => p.shippingType === shippingMethod.code || p.shippingType === 'tous');

    if (!!hasPromotion) {
        if (!hasPromotion.applyOnHeavy && !!isHeavy)
            return price;
        let result = price;
        if (!!totalShippingPrice) {
            if (!!hasPromotion?.keepApplicationFee || (!hasPromotion?.keepApplicationFee && hasPromotion.percentageDiscount < 1))
                result = result - applicationFee;
        }
        if (!!isApplicationFee) {
            if (!!(hasPromotion?.keepApplicationFee))
                return price;
            else
                return 0;
        }
        result = substractPercentage(result, hasPromotion.percentageDiscount || 0);
        return !!hasPromotion?.keepApplicationFee ? !!totalShippingPrice ? result + applicationFee : result : result;
    }
    return price;
}

const getLivraisonMessage = (promotion, shippingMethod, hasLightProduct) => {
    const shippingMethodTranslate = {
        "keepApplicationFee" : {
            "mondial-relay": "en livraison en point relais",
            "standard": "en livraison à domicile standard",
            "express": "en livraison express",
            "tous": ""
        },
        "applicationFee100": {
            "mondial-relay": "offerte en point relais",
            "standard": "à domicile standard offerte",
            "express": "express offerte",
            "tous": ""
        },
        "applicationFeeX": {
            "mondial-relay": "en point relais",
            "standard": "à domicile standard",
            "express": "express",
            "tous": ""
        },
        "applicationFee": {
            "mondial-relay": "en point relais offerts",
            "standard": "à domicile standard offerts",
            "express": "express offerts",
            "tous": ""
        },
    };
    const shippingType = promotion.shippingType === 'tous' ? 'tous' : shippingMethod.code;
    if (!promotion || !shippingMethod) return null;
    if (!hasLightProduct && !promotion.applyOnHeavy) return null;
    if (!!promotion.keepApplicationFee) {
        if (promotion.percentageDiscount > 0 && promotion.percentageDiscount <= 100) {
            return `Vous bénéficiez de l'avantage frais de port à l'article ${
                promotion.percentageDiscount >= 100 ? "offerts" : "réduits"} ${
                shippingMethodTranslate["keepApplicationFee"][shippingType]
            }`;
        }
    } else {
        if (promotion.percentageDiscount >= 100) {
            return `Vous bénéficiez de l'avantage livraison ${
                shippingMethodTranslate["applicationFee100"][shippingType]
            }`;
        } else if (promotion.percentageDiscount > 0 && promotion.percentageDiscount < 100) {
            return `Vous bénéficiez de l'avantage réduction sur livraison ${
                shippingMethodTranslate["applicationFeeX"][shippingType]
            }`;
        } else {
            return `Vous bénéficiez de l'avantage frais de livraison ${
                shippingMethodTranslate["applicationFee"][shippingType]
            }`;
        }
    }
}

export {
    shippingTypeCorrespondance,
    getDetails,
    substractPercentage,
    calculateShippingPromotion,
    getLivraisonMessage
}